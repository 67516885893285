// extracted by mini-css-extract-plugin
export var content = "FeatureSection-module--content--1a40a";
export var description = "FeatureSection-module--description--7c7c5";
export var featureSection = "FeatureSection-module--featureSection--1a504";
export var featureSectionInner = "FeatureSection-module--featureSectionInner--be873";
export var featureSectionPrimary = "FeatureSection-module--featureSectionPrimary--c74cd";
export var featureSectionSecondary = "FeatureSection-module--featureSectionSecondary--0a84d";
export var founder = "FeatureSection-module--founder--34621";
export var head = "FeatureSection-module--head--d89eb";
export var headSubtext = "FeatureSection-module--headSubtext--64ee1";
export var headText = "FeatureSection-module--headText--9f704";
export var image = "FeatureSection-module--image--0b1d0";
export var title = "FeatureSection-module--title--47668";