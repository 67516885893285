// src/components/FeatureSection.tsx

import React from 'react';
import ScrollAnimation from '../ScrollAnimation/ScrollAnimation';
import FormattedText from '../commons/FormattedText/FormattedText';
import Button from '../Button/Button';
import * as styles from './FeatureSection.module.css';

type ColorType = 'primary' | 'secondary';

type FeatureSectionProps = {
  imageSrc: string;
  imageAlt: string;
  title: string;
  description: string;
  sectionId: string;
  subTitle: string;
  colorType: ColorType;
  ctrLink: string;
  ctrText: string;
  founderName: string;
};

const FeatureSectionRight: React.FC<FeatureSectionProps> = ({
  imageSrc,
  imageAlt,
  title,
  description,
  sectionId,
  subTitle,
  colorType,
  ctrLink,
  ctrText,
  founderName,
}) => {
  let featureSectionClass = styles.featureSectionPrimary; // デフォルトのスタイル
  if (colorType === 'primary') {
    featureSectionClass = styles.featureSectionPrimary;
  } else if (colorType === 'secondary') {
    featureSectionClass = styles.featureSectionSecondary;
  }
  return (
    <ScrollAnimation>
      <section className={featureSectionClass} id={sectionId}>
        <div className={styles.featureSectionInner}>
          <img className={styles.image} src={imageSrc} alt={imageAlt} />
          <div className={styles.content}>
            <h2 className={styles.head}>
              <span className={styles.headText}>{title}</span>
              {subTitle && (
                <span className={styles.headSubtext}>{subTitle}</span>
              )}
            </h2>
            <p className={styles.description}>
              <FormattedText text={description} />
            </p>
            {ctrLink && <Button text={ctrText} ctaLink={ctrLink} />}
          </div>
        </div>
        {founderName && (
          <div className={styles.founder}>
            <p>{founderName}</p>
          </div>
        )}
      </section>
    </ScrollAnimation>
  );
};

export default FeatureSectionRight;
